import React from 'react';
import JoeSplashPic from '../assets/JoeSplashPic.png';
import { useLocation } from 'react-router-dom';

const AboutCard = () => {
  const location = useLocation();

  const getDescription = () => {
    switch (location.pathname) {
      case '/':
        return (
          <>
            <h2>HaywireJo</h2>
            <p>
              Hi there! I'm Joe, a pixel artist and illustrator! I have a love
              and passion for pixel game art, so I create some myself. Hope you
              enjoy!
            </p>
          </>
        );
        break;
      case '/game':
        return (
          <>
            <h2>HaywireJo</h2>
            <p>
              2D Game Developer working on cute and fun games. Working in Unity,
              specializing in game art, programming, and design. All my work is hosted on <a href='https://haywirejo.itch.io/' target='_blank'>Itch.io</a>, thanks in
              advance for playing!
            </p>
          </>
        );
        break;
      default:
        return <p>Regular Person</p>;
    }
  };

  return (
    <>
      <section className="about-card">
        <table>
          <tbody>
            <tr>
              <td>
                <article>{getDescription()}</article>
              </td>
              <td>
                <img src={JoeSplashPic} alt="Joe Splash Pic" />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};

export default AboutCard;
