import React from 'react';
import screenshot from './Screenshots/SS1.PNG';
import screenshot2 from './Screenshots/MMScreenshotRoyalCatyard.PNG';
import screenshot3 from './Screenshots/NightSlashScreenshot.png';
import DorDScreenshot from './Screenshots/DodgeOrDie.png';
import NecroKingScreenshot from './Screenshots/NecroKing.png';
import WreckScreenshot from './Screenshots/Wreckipeligo.png';
import JourneyScreenshot from './Screenshots/JourneyToTheCup.png'
import ProjectCard from '../Helpers/ProjectCard';

const GameDev = () => {
  return (
    <>
      <div className="info">
        <section className="game-projects-container">
          <h3>Solo Dev</h3>
          <ul>
            <li>
              <ProjectCard
                class="game-project"
                imgSource={screenshot2}
                title="Maneki Mancer"
                description="Arcade style platformer where you collect coins and use them to defeat enemies and advance through stages."
                links={[
                  {
                    name: 'Play Now',
                    href: 'https://haywirejo.itch.io/maneki-mancer',
                  },
                ]}
              ></ProjectCard>
            </li>
            <li>
              <ProjectCard
                class="game-project"
                imgSource={screenshot3}
                title="Night Slash"
                description="Slash, clash'em up as a Vampiric Samurai in the name of vengeance. How long can you last into the night?"
                links={[
                  {
                    name: 'Play Now',
                    href: 'https://haywirejo.itch.io/night-slash',
                  },
                ]}
              ></ProjectCard>
            </li>
            <li>
              <ProjectCard
                class="game-project"
                imgSource={screenshot}
                title="Fort Mortem"
                description="Strategic survival game where you clear each level by coordinating your defense to outlast the enemy's offensive!"
                links={[
                  {
                    name: 'Play Now',
                    href: 'https://haywirejo.itch.io/fort-mortem',
                  },
                ]}
              ></ProjectCard>
            </li>
          </ul>
          <br></br>
          <br></br>
          <h3>Lead Artist Credits</h3>
          <ul>
          <li>
              <ProjectCard
                class="game-project"
                imgSource={NecroKingScreenshot}
                title="Necro King"
                description="Strategic mob fighter where you play as the mob of undead forces! Balance your strength in numbers as you survive as long as possible. Placed 41st overall in Wowie Jam 4.0"
                links={[
                  {
                    name: 'Play Now',
                    href: 'https://nothing-d.itch.io/necroking',
                  },
                  {
                    name: 'View Submission',
                    href: 'https://itch.io/jam/wowie-jam-4/rate/1628609',
                  }
                ]}
              ></ProjectCard>
            </li>
            <li>
              <ProjectCard
                class="game-project"
                imgSource={DorDScreenshot}
                title="Dodge or Die"
                description="Arcade style boss rush where rolling is the name of the game. Placed 98th overall in GMTK Game Jam 2022"
                links={[
                  {
                    name: 'Play Now',
                    href: 'https://nothing-d.itch.io/dodge-or-die',
                  },
                  {
                    name: 'View Submission',
                    href: 'https://itch.io/jam/gmtk-jam-2022/rate/1622621',
                  },
                ]}
              ></ProjectCard>
            </li>
            <li>
              <ProjectCard
                class="game-project"
                imgSource={JourneyScreenshot}
                title="Journey To The Cup"
                description="Short and playful title taking you on a journey as a soccer ball as you find your way to the World Cup! Placed 3rd in World Cup Jam"
                links={[
                  {
                    name: 'Play Now',
                    href: 'https://nothing-d.itch.io/journey-to-the-cup',
                  },
                  {
                    name: 'View Submission',
                    href: 'https://gamejolt.com/games/journey-to-the-cup/759210',
                  },
                ]}
              ></ProjectCard>
            </li>
            <li>
              <ProjectCard
                class="game-project"
                imgSource={WreckScreenshot}
                title="Wreckipeligo"
                description="Grid based puzzle game where you navigate islands and avoid hostile wildlife to make it to safety! Placed 10th overall in Mini Jam 134: Islands²"
                links={[
                  {
                    name: 'Play Now',
                    href: 'https://kandistar.itch.io/wreckipeligo',
                  },
                  {
                    name: 'View Submission',
                    href: 'https://itch.io/jam/mini-jam-134-islands/rate/2113954',
                  },
                ]}
              ></ProjectCard>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default GameDev;
